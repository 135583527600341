import Chart from 'react-apexcharts';

import tr from './ApexChartLocalization.json';

const ApexChart = ({ options, series }) => {
  // Turkish localization
  options.chart = {
    locales: [tr],
    defaultLocale: 'tr'
  };

  options.colors = ['#6259ca', '#a29ece'];

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width={810}
      height={350}
    />
  );
};

export default ApexChart;
