import { PercentageOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/pageItems.scss';
import {
  useGetAdminDashboardStats,
  useGetModules,
  usePostStatistics
} from '@src/api/methods';
import pharmacy_icon from '@src/assets/img/pharmacy-icon.png';
import '@src/assets/sharedStyles/formItems.scss';
import InfoMessageCard from '@src/components/shared/InfoMessageCard/InfoMessageCard';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { DatePicker, Select, Spin, Tabs } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import AdminGraphCards from './AdminStatsParts/AdminGraphCards';
import { CompanyStats } from './CompanyStats';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

function formatLargeNumber(number) {
  if (number == null) {
    return 0;
  }
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + ' M';
  }
  return number.toLocaleString('tr-TR');
}

function formatCurrencyValue(number) {
  if (number == null) {
    return 0;
  }
  // If number is 100 million or greater (9+ digits), format as millions
  if (number >= 100000000) {
    return (number / 1000000).toFixed(1).replace('.', ',') + ' M';
  }
  // Otherwise format with dots as thousand separators
  return number.toLocaleString('tr-TR', {
    maximumFractionDigits: 0
  });
}

function AdminDashboard() {
  const [stats, setStats] = useState({});
  const [graphStats, setGraphStats] = useState({});

  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedDates, setSelectedDates] = useState(null);
  const [selectedGraphModule, setSelectedGraphModule] = useState(null);
  const { getNotification } = useNotificationPopup();
  const { mutate: statisticsMutate, isPending: statisticsLoading } =
    usePostStatistics();
  const { data: modules, isLoading: modulesLoading } = useGetModules();
  const { data: cardStats, isLoading: cardStatsLoading } =
    useGetAdminDashboardStats();

  useEffect(() => {
    statisticsMutate(
      {
        startDate: null,
        endDate: null,
        moduleId: null
      },
      {
        onSuccess: (data) => {
          setStats({
            acceptedRequestCount: data.acceptedRequestCount,
            acceptedTotalPayment: data.acceptedTotalPayment,
            kkiProductCount: data.kkiProductCount,
            stockProductCount: data.stockProductCount,
            pendingRequestCount: data.pendingRequestCount,
            pendingTotalPayment: data.pendingTotalPayment
          });
          setGraphStats({
            graphResult: data.graphResult,
            graphResultKki: data.graphResultKki,
            graphResultStock: data.graphResultStock
          });
        },
        onError: (error) => {
          getNotification({
            ...getErrorMessage(error)
          });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedGraphModule) {
      statisticsMutate(
        {
          startDate: null,
          endDate: null,
          moduleId: selectedGraphModule
        },
        {
          onSuccess: (data) => {
            setGraphStats(data);
          }
        },
        {
          onError: (error) => {
            getNotification({
              ...getErrorMessage(error)
            });
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGraphModule, statisticsMutate]);

  const moduleOptions = useMemo(() => {
    if (modules) {
      return modules.map((module) => ({
        label: module.name,
        value: module.moduleId
      }));
    }
    return [];
  }, [modules]);

  const handleModuleChange = (value) => {
    setSelectedModule(value);

    statisticsMutate(
      {
        startDate: selectedDates?.[0] ?? null,
        endDate: selectedDates?.[1] ?? null,
        moduleId: value
      },
      {
        onSuccess: (data) => {
          setStats(data);
        }
      },
      {
        onError: (error) => {
          getNotification({
            ...getErrorMessage(error)
          });
        }
      }
    );
  };

  const handleDatesChange = (dates) => {
    setSelectedDates(dates);

    const firstDate = dates?.[0] ? dates[0].format('YYYY-MM-DD') : null;
    const lastDate = dates?.[1] ? dates[1].format('YYYY-MM-DD') : null;

    statisticsMutate(
      {
        startDate: firstDate,
        endDate: lastDate,
        moduleId: selectedModule
      },
      {
        onSuccess: (data) => {
          setStats(data);
        }
      },
      {
        onError: (error) => {
          getNotification({
            ...getErrorMessage(error)
          });
        }
      }
    );
  };

  const handleGraphModuleChange = (value) => {
    setSelectedGraphModule(value);
  };

  const getProductCount = useMemo(() => {
    const productNames = modules?.map((module) => module.name.split(' ')[0]);
    const uniqueProductNames = [...new Set(productNames)];
    return uniqueProductNames.length;
  }, [modules]);

  return (
    <div className="kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane
          tab="Genel"
          key="1"
          className="flex flex-col 2xl:flex-row gap-4"
        >
          <div className="w-full 2xl:w-9/12 flex flex-col gap-8">
            <div className="flex flex-col gap-4 shadow-[1px_1px_5px_rgba(0,0,0,0.15)] rounded-md p-4">
              <div className="flex flex-row gap-4 p-4">
                <Select
                  placeholder="Tüm Modüller"
                  className="w-9/12 kemi_form_select_grey"
                  options={moduleOptions}
                  value={selectedModule}
                  loading={modulesLoading}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  onChange={handleModuleChange}
                  allowClear
                />
                <RangePicker
                  placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
                  className="w-3/12 bg-gray-200 "
                  value={selectedDates}
                  onChange={handleDatesChange}
                  format="DD-MM-YYYY"
                />
              </div>

              <div className="w-full flex flex-row justify-around gap-4 pb-4">
                <div className="flex flex-col gap-4 w-full">
                  <span className="text-center text-base">Bekleyen Ödeme</span>
                  <span className="text-center text-xl font-semibold">
                    ₺{' '}
                    {statisticsLoading ? (
                      <Spin />
                    ) : (
                      formatCurrencyValue(stats.pendingTotalPayment)
                    )}
                  </span>
                </div>
                <div className="relative flex flex-col gap-4 w-full">
                  <span className="text-center text-base">Onaylanan Ödeme</span>
                  <span className="text-center text-xl font-semibold">
                    ₺{' '}
                    {statisticsLoading ? (
                      <Spin />
                    ) : (
                      formatCurrencyValue(stats.acceptedTotalPayment)
                    )}
                  </span>
                </div>
                <div className="w-[2px] bg-gray-400 h-full"></div>
                <div className="flex flex-col gap-4 w-full">
                  <span className="text-center text-base">
                    Taratılan Karekod
                  </span>
                  <span className="text-center text-xl font-semibold">
                    {statisticsLoading ? (
                      <Spin />
                    ) : (
                      formatLargeNumber(
                        stats?.kkiProductCount + stats?.stockProductCount
                      )
                    )}
                  </span>
                </div>
                <div className="flex flex-col gap-4 w-full">
                  <span className="text-center text-base">
                    Bekleyen Başvuru
                  </span>
                  <span className="text-center text-xl font-semibold">
                    {statisticsLoading ? (
                      <Spin />
                    ) : (
                      formatLargeNumber(stats?.pendingRequestCount)
                    )}
                  </span>
                </div>
                <div className="flex flex-col gap-4 w-full relative">
                  <span className="text-center text-base">
                    Onaylanan Başvuru
                  </span>
                  <span className="text-center text-xl font-semibold relative z-10">
                    {statisticsLoading ? (
                      <Spin />
                    ) : (
                      formatLargeNumber(stats?.acceptedRequestCount)
                    )}
                  </span>
                  <div className="absolute -top-7 right-0 flex items-center justify-center z-0">
                    <PercentageOutlined
                      style={{ opacity: 0.1 }}
                      className="text-9xl text-darkPurple"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 rounded-md shadow-[1px_1px_5px_rgba(0,0,0,0.15)] p-4">
              <div className="flex flex-row gap-4 p-4">
                <Select
                  placeholder="Tüm Modüller"
                  className="w-full kemi_form_select_grey"
                  options={moduleOptions}
                  value={selectedGraphModule}
                  loading={modulesLoading}
                  onChange={handleGraphModuleChange}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  showSearch
                  allowClear
                />
              </div>
              <div className="w-full flex justify-center items-center">
                <AdminGraphCards
                  graphStats={graphStats}
                  loading={statisticsLoading}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-row 2xl:flex-col gap-4 w-full 2xl:w-3/12 px-4">
            <InfoMessageCard
              className="w-full"
              icon={pharmacy_icon}
              title="ÜYE ECZANE"
              description={`${
                cardStatsLoading ? <Spin /> : cardStats.numberOfPharmacies
              }`}
            />
            <InfoMessageCard
              className="w-full"
              title="AKTİF MODÜL"
              description={`${
                cardStatsLoading ? <Spin /> : cardStats.numberOfActiveModules
              }`}
            />
            <InfoMessageCard
              className="w-full"
              title="KAYITLI ÜRÜN"
              description={getProductCount}
              // description={`${stats.numberOfProducts}`}
            />
          </div>
        </TabPane>
        <TabPane tab="Detay*" key="2">
          <CompanyStats />
        </TabPane>
        {/* <TabPane tab="Kontrol" key="3">
          <Empty description="Kontrol Bekleyen İşlem Bulunmamaktadır" />
        </TabPane> */}
      </Tabs>
    </div>
  );
}

export default AdminDashboard;
