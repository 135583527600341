import { usePost } from '@src/api/methods';
import { getColumnSearchProps } from '@src/utils/getColumnSearchProps';
import { Button, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './UsersTab.scss';
import NewUserModal from './UsersTab/NewUserModal';

function UsersTab() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data, isPending, mutate } = usePost('admin/users');

  useEffect(() => {
    mutate({
      userType: 'ADMIN'
    });
  }, [mutate]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: 'İsim - Soyisim',
      key: 'nameAndSurname',
      render: (record) => `${record.name} ${record.surname}`
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps(
        'email',
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn
      )
    },
    {
      title: 'Üyelik Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ...getColumnSearchProps(
        'createdAt',
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn
      )
    }
  ];

  const handleNewAdminUserModalVisible = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="admin-users-tab__">
      <div className="buttons-container">
        <Button
          style={{ width: 110 }}
          className="kemi_form_btn_purple"
          onClick={() => setIsModalVisible(true)}
        >
          Yeni Oluştur
        </Button>
      </div>
      <Table
        scroll={{ x: 1499 }}
        columns={columns}
        dataSource={data}
        loading={isPending}
      />

      <NewUserModal
        key={'adminNewUser'}
        handleModalVisible={handleNewAdminUserModalVisible}
        isModalVisible={isModalVisible}
        refecthAdminUsers={mutate}
      />
    </div>
  );
}

export default UsersTab;
