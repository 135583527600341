import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EditOutlined
} from '@ant-design/icons';
import { usePost } from '@src/api/methods';
import { getColumnSearchProps } from '@src/utils/getColumnSearchProps';
import { Button, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './UsersTab.scss';
import NewCompanyModal from './UsersTab/NewCompanyModal';
import NewUserModal from './UsersTab/NewUserModal';
import UpdateCompanyModal from './UsersTab/UpdateCompanyModal';
function UsersTab() {
  const [isNewCompanyUserModalVisible, setIsNewCompanyUserModalVisible] =
    useState(false);
  const [isNewCompanyModalVisible, setIsNewCompanyModalVisible] =
    useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const { data, isPending, mutate } = usePost('admin/users');

  useEffect(() => {
    mutate({
      userType: 'WORKER'
    });
  }, [mutate]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const handleUpdateModalOpen = (record) => {
    setSelectedUser(record);
    setIsUpdateModalVisible(true);
  };

  const handleUpdateModalClose = () => {
    setIsUpdateModalVisible(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      title: 'İsim - Soyisim',
      key: 'nameAndSurname',
      render: (record) => `${record.name} ${record.surname}`
    },
    {
      title: 'Firma',
      dataIndex: ['company', 'name'],
      key: 'companyName'
    },
    {
      title: 'E-Posta',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps(
        'email',
        handleSearch,
        handleReset,
        searchInput,
        searchText,
        searchedColumn
      )
    },
    { title: 'Üyelik Tarihi', dataIndex: 'createdAt', key: 'createdAt' },
    {
      title: 'Aktif',
      key: 'status',
      render: (record) => {
        return record.isActive ? (
          <div className="flex items-center ml-2">
            <CheckCircleOutlined className="text-green-500 text-lg" />
          </div>
        ) : (
          <div className="flex items-center ml-2">
            <CloseCircleOutlined className="text-red-500 text-lg" />
          </div>
        );
      }
    },
    {
      title: '',
      key: 'actions',
      render: (record) => {
        return (
          <div className="flex flex-row gap-2">
            <Button
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleUpdateModalOpen(record)}
              className="border-none rounded-md flex items-center justify-center w-8 h-8"
              title="Güncelle"
            />
          </div>
        );
      }
    }
  ];

  const handleNewCompanyUserModalVisible = () => {
    setIsNewCompanyUserModalVisible(false);
  };

  const handleNewCompanyModalVisible = () => {
    setIsNewCompanyModalVisible(false);
  };

  return (
    <div className="company-users-tab__">
      <div className="buttons-container">
        <Button
          style={{ width: 150, marginRight: '15px' }}
          className="kemi_form_btn_purple"
          onClick={() => setIsNewCompanyModalVisible(true)}
        >
          Yeni Firma Oluştur
        </Button>
        <Button
          style={{ width: 160 }}
          className="kemi_form_btn_purple"
          onClick={() => setIsNewCompanyUserModalVisible(true)}
        >
          Yeni Kullanıcı Oluştur
        </Button>
      </div>
      <Table
        scroll={{ x: 1499 }}
        columns={columns}
        dataSource={data}
        loading={isPending}
        rowKey="id"
      />
      <NewCompanyModal
        key={'adminNewCompany'}
        handleModalVisible={handleNewCompanyModalVisible}
        isModalVisible={isNewCompanyModalVisible}
      />
      <NewUserModal
        key={'companyNewUser'}
        handleModalVisible={handleNewCompanyUserModalVisible}
        isModalVisible={isNewCompanyUserModalVisible}
        refetchCompanyUsers={mutate}
      />
      {selectedUser && (
        <UpdateCompanyModal
          key={`updateUser-${selectedUser.id}`}
          handleModalVisible={handleUpdateModalClose}
          isModalVisible={isUpdateModalVisible}
          userData={selectedUser}
          refetchCompanyUsers={mutate}
        />
      )}
    </div>
  );
}

export default UsersTab;
