import { Card, Col, Row } from 'antd';
import './InfoMessageCard.scss';

function InfoMessageCard({ icon, title, description, className }) {
  return (
    <Card className={`InfoMessageCard ${className}`}>
      <Row justify="space-between">
        <Col span={24}>
          <h2 className="text-white font-bold text-center">{title}</h2>
          <h1 className="text-4xl font-bold text-white text-center">
            {description}
          </h1>
        </Col>
        {icon && (
          <img
            className="absolute -right-12 top-5 z-10 w-28"
            src={icon}
            alt="kemi_welcome_img"
          />
        )}
      </Row>
    </Card>
  );
}

export default InfoMessageCard;
