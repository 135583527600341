import '@assets/sharedStyles/modal.scss';
import { apiInstance } from '@src/api/axiosConfig';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Input, Modal, Switch, message } from 'antd';
import { useEffect } from 'react';
import { GrFormClose } from 'react-icons/gr';

function UpdateCompanyModal({
  isModalVisible,
  handleModalVisible,
  userData,
  refetchCompanyUsers
}) {
  const [updateUserForm] = Form.useForm();

  const { mutate: userPatchMutate, isLoading: userPatchLoading } = useMutation({
    mutationKey: ['patch', 'user'],
    mutationFn: (payload) =>
      apiInstance.patch(`/admin/user/${userData.userId}`, payload),
    onSuccess: () => {
      refetchCompanyUsers({ userType: 'WORKER' });
    }
  });

  useEffect(() => {
    if (userData && isModalVisible) {
      updateUserForm.setFieldsValue({
        name: userData.name,
        surname: userData.surname,
        email: userData.email,
        isActive: userData.isActive
      });
    }
  }, [userData, isModalVisible, updateUserForm]);

  const submitForm = (values) => {
    const payload = {
      ...values,
      isActive: values.isActive ? 1 : 0
    };

    userPatchMutate(payload, {
      onSuccess: () => {
        message.success('Kullanıcı bilgileri güncellendi!', 5);
        handleModalVisible();
        updateUserForm.resetFields();
      },
      onError: (error) => {
        console.error('Update user error:', error);
        message.error('Kullanıcı güncelleme başarısız!', 5);
      }
    });
  };

  const CloseIcon = () => (
    <div className="close-icon-container">
      <GrFormClose size={25} color="#fffafe" />
    </div>
  );

  // Close modal and reset form on cancel
  const handleCancel = () => {
    handleModalVisible();
    updateUserForm.resetFields();
  };

  return (
    <Modal
      className="modal"
      centered
      open={isModalVisible}
      closeIcon={<CloseIcon />}
      footer={null}
      title={'Kullanıcı Bilgilerini Güncelle'} // Updated Title
      onCancel={handleCancel} // Use custom cancel handler
      width={600}
    >
      <div className="modal-context-container">
        <Form
          className="modal-context-form"
          form={updateUserForm}
          onFinish={submitForm}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
        >
          <Form.Item
            label="İsim"
            name="name"
            rules={[{ required: true, message: 'Lütfen isim girin!' }]}
          >
            <Input className="ml-2" placeholder={'Kullanıcının adı'} />
          </Form.Item>

          <Form.Item
            label="Soyisim"
            name="surname"
            rules={[{ required: true, message: 'Lütfen soyisim girin!' }]}
          >
            <Input className="ml-2" placeholder={'Kullanıcının soyadı'} />
          </Form.Item>

          <Form.Item
            label="E-Posta"
            name="email"
            rules={[
              { required: true, message: 'Lütfen e-posta adresi girin!' },
              { type: 'email', message: 'Geçerli bir e-posta adresi girin!' }
            ]}
          >
            <Input
              className="ml-2"
              placeholder={'kullanici@example.com'}
              type="email"
            />
          </Form.Item>

          <Form.Item
            label="Aktif"
            name="isActive"
            valuePropName="checked"
            className="ml-2"
          >
            <Switch className="flex w-1/2" />
          </Form.Item>
        </Form>
        <div className="modal-context-footer">
          <Button
            className="modal-button"
            onClick={updateUserForm.submit}
            loading={userPatchLoading}
          >
            Güncelle
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default UpdateCompanyModal;
