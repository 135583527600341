import { env } from '@src/env';
import { useMutation, useQuery } from '@tanstack/react-query';
import { apiInstance } from './axiosConfig';

export const backendBaseUrl = env.REACT_APP_BACKEND_URL;

export const useGet = (url) => {
  return useQuery({
    queryKey: [url],
    queryFn: () => apiInstance.get(url).then((res) => res.data),
    refetchOnWindowFocus: false
  });
};

export const usePost = (url) => {
  const mutation = useMutation({
    mutationKey: [url],
    mutationFn: async (payload) => {
      try {
        const response = await apiInstance.post(url, payload);
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  });

  return mutation;
};

export const usePatch = (url, payload) => {
  return useMutation({
    mutationKey: [url],
    mutationFn: () => apiInstance.patch(url, payload)
  });
};

export const useDelete = (url, id) => {
  return useMutation({
    mutationKey: [url],
    mutationFn: () => apiInstance.delete(`${url + id}`)
  });
};

export const useDeleteWithBody = (url, id, body) => {
  return useMutation({
    mutationKey: [url],
    mutationFn: () =>
      apiInstance.delete(`${backendBaseUrl}${url + id}`, { data: body })
  });
};

export const useUpdateProductDetail = () => {
  return useMutation({
    mutationKey: ['updateProductDetail'],
    mutationFn: async (payload) => {
      try {
        const response = await apiInstance.post(
          '/admin/product-detail',
          payload
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  });
};

export const getProductImage = async ({ productId, requestId }) => {
  const response = await apiInstance.post(
    'product-image',
    {
      productId,
      requestId
    },
    {
      responseType: 'blob'
    }
  );

  return response.data;
};

export const useGetProductImage = () => {
  return useMutation({
    mutationKey: ['product', 'image'],
    mutationFn: (data) => getProductImage(data)
  });
};

export const getStakeholder = async () => {
  try {
    const response = await apiInstance.get(`admin/stakeholder`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useGetStakeholder = () => {
  return useQuery({
    queryKey: ['allStakeholder'],
    queryFn: () => getStakeholder(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });
};

/**
 * @typedef {Object} StatisticsPayload
 * @property {string} startDate - Start date in ISO format (YYYY-MM-DD)
 * @property {string} endDate - End date in ISO format (YYYY-MM-DD)
 * @property {string} moduleId - ID of the module to get statistics for
 */

/**
 * Posts statistics data to the server
 * @param {StatisticsPayload} payload
 */
const postStatistics = async (payload) => {
  try {
    const response = await apiInstance.post(
      `admin/request-statistics`,
      payload
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Hook for posting statistics with date range and module information
 * @returns {import('@tanstack/react-query').UseMutationResult}
 * @example
 * const { mutate } = usePostStatistics();
 * mutate({ startDate: '2024-03-20', endDate: '2024-03-21', moduleId: '123' });
 */
export const usePostStatistics = () => {
  return useMutation({
    mutationKey: ['postStatistics'],
    mutationFn: (payload) => postStatistics(payload)
  });
};

const getModules = async () => {
  try {
    const response = await apiInstance.get(`admin/modules`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useGetModules = () => {
  return useQuery({
    queryKey: ['modules'],
    queryFn: () => getModules(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });
};

const getCompanyModules = async () => {
  try {
    const response = await apiInstance.get(`companypages/modules`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useGetCompanyModules = () => {
  return useQuery({
    queryKey: ['companyModules'],
    queryFn: () => getCompanyModules(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });
};

const getAdminStats = async () => {
  try {
    const response = await apiInstance.get(`admin/stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useGetAdminDashboardStats = () => {
  return useQuery({
    queryKey: ['adminDashboardStats'],
    queryFn: () => getAdminStats(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });
};

const getCompanyStats = async () => {
  try {
    const response = await apiInstance.get(`companypages/stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const useGetCompanyStats = () => {
  return useQuery({
    queryKey: ['companyStats'],
    queryFn: () => getCompanyStats(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity
  });
};
