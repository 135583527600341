import { usePost } from '@src/api/methods';
import { Button, Select, Table, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import './UsersTab.scss';
import UpdateUserModal from './UsersTab/UpdateUserModal';

const Users = () => {
  const [sortedUsersDataByCreatedAt, setSortedUsersDataByCreatedAt] = useState(
    []
  );
  const [selectedGLN, setSelectedGLN] = useState(null);
  const [selectedPharmacyName, setSelectedPharmacyName] = useState(null);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [selectedITSEmail, setSelectedITSEmail] = useState(null);

  const {
    data: usersData,
    isPending: usersDataLoading,
    mutate: mutateUsersData
  } = usePost('admin/users');

  const { mutate: mutateVerifyResend } = usePost('admin/verify-resend');

  useEffect(() => {
    mutateUsersData({
      userType: 'PHARMACY'
    });
  }, [mutateUsersData]);

  useEffect(() => {
    if (usersData) {
      const sortedUsersData = [...usersData].sort((a, b) => {
        function toISODate(dateString) {
          const [datePart, timePart] = dateString.split('-');
          const [day, month, year] = datePart.split('.');
          return `${year}-${month}-${day}T${timePart}`;
        }
        const dateA = new Date(toISODate(a.createdAt));
        const dateB = new Date(toISODate(b.createdAt));
        return dateB - dateA;
      });
      setSortedUsersDataByCreatedAt(sortedUsersData);
    }
  }, [usersData]);

  const sendVerifyLink = ({ type, pharmacyId }) => {
    mutateVerifyResend(
      { pharmacyId, emailAddressType: type },
      {
        onSuccess: () => {
          message.success(
            `${
              type === 'ITS' ? 'İTS e-postasına' : 'Üyelik e-postasına'
            } aktivasyon gönderimi başarılı!`,
            5
          );
        },
        onError: () => {
          message.error(
            `${
              type === 'ITS' ? 'İTS e-postasına' : 'Üyelik e-postasına'
            } e-postasına aktivasyon gönderimi başarısız!`,
            5
          );
        }
      }
    );
  };

  const handleXLSXDownloadButton = () => {
    const flattenedData = filteredData.map((item) => ({
      GLN: item.pharmacy.gln,
      'Eczane İsim': item.pharmacy.name,
      Şehir: item.pharmacy?.address?.city?.cityName ?? '', // Placeholder if city is not provided
      İlçe: item.pharmacy?.address?.province?.provinceName ?? '', // Placeholder if district is not provided
      'Kullanıcı E-Mail': item.email,
      'İTS E-Mail': item.pharmacy.stakeholder.itsEmail,
      'Kullanıcı İsmi': item.name,
      'Kullanıcı Soy İsmi': item.surname,
      'Aktif/Deaktif': item.isActive ? 'Aktif' : 'Deaktif',
      'Veri Paylaşımı': item.dataprotection?.emailConsent ? 'Evet' : 'Hayır',
      GSM: item.phone,
      'Kayıt Tarihi': item.createdAt
    }));

    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(
      workbook,
      `${new Date().toLocaleDateString('tr')} PharmacyUsers.xlsx`
    );
  };

  const glnOptions = useMemo(() => {
    return (
      usersData?.map((user) => ({
        value: user.pharmacy.gln,
        label: user.pharmacy.gln
      })) || []
    );
  }, [usersData]);

  const pharmacyNameOptions = useMemo(() => {
    return (
      usersData?.map((user) => ({
        value: user.pharmacy.name,
        label: user.pharmacy.name
      })) || []
    );
  }, [usersData]);

  const accountEmailOptions = useMemo(() => {
    return (
      usersData?.map((user) => ({
        value: user.email,
        label: user.email
      })) || []
    );
  }, [usersData]);

  const itsEmailOptions = useMemo(() => {
    return (
      usersData?.map((user) => ({
        value: user.pharmacy.stakeholder.itsEmail,
        label: user.pharmacy.stakeholder.itsEmail
      })) || []
    );
  }, [usersData]);

  const filteredData = useMemo(() => {
    return (
      sortedUsersDataByCreatedAt?.filter((user) => {
        return (
          (!selectedGLN || user.pharmacy.gln === selectedGLN) &&
          (!selectedPharmacyName ||
            user.pharmacy.name === selectedPharmacyName) &&
          (!selectedEmail || user.email === selectedEmail) &&
          (!selectedITSEmail ||
            user.pharmacy.stakeholder.itsEmail === selectedITSEmail)
        );
      }) || []
    );
  }, [
    sortedUsersDataByCreatedAt,
    selectedGLN,
    selectedPharmacyName,
    selectedEmail,
    selectedITSEmail
  ]);

  const columns = [
    {
      title: 'GLN',
      dataIndex: ['pharmacy', 'gln'],
      key: 'GLN'
    },
    {
      title: 'Eczane İsmi',
      dataIndex: ['pharmacy', 'name'],
      key: 'pharmacyName'
    },
    { title: 'Kullanıcı İsmi', dataIndex: 'name', key: 'name' },
    { title: 'Üyelik E-Posta', dataIndex: 'email', key: 'email' },
    {
      title: 'İTS E-Posta',
      dataIndex: ['pharmacy', 'stakeholder', 'itsEmail']
    },
    { title: 'Aktif', dataIndex: 'isActive', key: 'isActive' },
    { title: 'GSM', dataIndex: 'phone', key: 'phone', width: 130 },
    {
      title: 'Üyelik Tarihi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => createdAt.split('-')[0],
      sorter: (a, b) => {
        function toISODate(dateString) {
          const [datePart, timePart] = dateString.split('-');
          const [day, month, year] = datePart.split('.');
          return `${year}-${month}-${day}T${timePart}`;
        }
        const dateA = new Date(toISODate(a.createdAt));
        const dateB = new Date(toISODate(b.createdAt));
        return dateA - dateB;
      },
      width: 130
    },
    {
      title: '',
      key: 'buttons',
      render: (rowData) => {
        return (
          <div className="activation-button-container">
            <span
              onClick={() =>
                sendVerifyLink({
                  type: 'PHARMACY',
                  pharmacyId: rowData.pharmacy.pharmacyId
                })
              }
              className="activation-button"
            >
              KP
            </span>
            <span
              onClick={() =>
                sendVerifyLink({
                  type: 'ITS',
                  pharmacyId: rowData.pharmacy.pharmacyId
                })
              }
              className="activation-button"
              style={{ margin: '0px 7px' }}
            >
              İTS
            </span>
            <UpdateUserModal
              rowData={rowData}
              key={rowData.pharmacy.pharmacyId}
              refetchPharmacyUsers={mutateUsersData}
            />
          </div>
        );
      },
      width: 70
    }
  ];

  return (
    <div className="pharmacy-users-tab__">
      <div className="form-container">
        <div>
          <Select
            className="kemi_form_select"
            style={{ width: 241 }}
            placeholder="GLN"
            options={glnOptions}
            showSearch
            allowClear
            onChange={(value) => setSelectedGLN(value)}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
          <Select
            className="kemi_form_select"
            style={{ width: 241 }}
            placeholder="Eczane İsmi"
            options={pharmacyNameOptions}
            showSearch
            allowClear
            onChange={(value) => setSelectedPharmacyName(value)}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
          <Select
            className="kemi_form_select"
            style={{ width: 241 }}
            placeholder="Üyelik E-Posta"
            options={accountEmailOptions}
            showSearch
            allowClear
            onChange={(value) => setSelectedEmail(value)}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
          <Select
            className="kemi_form_select"
            style={{ width: 241 }}
            placeholder="İTS E-Posta"
            options={itsEmailOptions}
            showSearch
            allowClear
            onChange={(value) => setSelectedITSEmail(value)}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
        <div>
          <Button
            style={{ width: 110 }}
            className="kemi_form_btn_green"
            onClick={handleXLSXDownloadButton}
          >
            .xlsx İndir
          </Button>
        </div>
      </div>

      <Table
        id="pharmacy-users-table"
        columns={columns}
        dataSource={filteredData}
        loading={usersDataLoading}
      />
    </div>
  );
};

export default Users;
