import ApexChart from '@src/components/shared/ApexChart';
import { Spin } from 'antd';

const CompanyGraphCards = ({ graphStats, loading }) => {
  // Early return if no data
  if (!graphStats?.graphResult) {
    return null;
  }

  // Transform data for the chart
  const transformedData = graphStats.graphResult.reduce(
    (acc, item) => {
      // Extract month name from yearMonth
      const date = new Date(item.yearMonth);
      const monthName = date.toLocaleString('tr-TR', { month: 'short' });

      // Add data to accumulator
      acc.months.push(monthName);
      acc.totalProducts.push(item.totalProducts);
      acc.productWithPayment.push(item.productWithPayment);
      acc.uniquePharmacies.push(item.uniquePharmacies);

      return acc;
    },
    {
      months: [],
      totalProducts: [],
      productWithPayment: [],
      uniquePharmacies: []
    }
  );
  const options = {
    series: [
      {
        name: 'Toplam Karekod',
        color: '#6259ca',
        type: 'bar',
        data: transformedData.totalProducts
      },
      {
        name: 'Geçerli Karekod',
        color: '#a29ece',
        type: 'bar',
        data: transformedData.productWithPayment
      },
      {
        name: 'Başvuran Eczane',
        color: 'orange',
        type: 'line',
        data: transformedData.uniquePharmacies
      }
    ],
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: [0, 0, 3],
      curve: 'smooth'
    },
    fill: {
      opacity: [1, 1, 1]
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: transformedData.months,
      type: 'category'
    },
    yaxis: [
      {
        title: {
          text: 'Karekod Sayısı'
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        borderRadius: 4
      }
    },
    legend: {
      position: 'top'
    }
  };

  return (
    <Spin spinning={loading}>
      <ApexChart
        type="line"
        width={510}
        height={350}
        series={options.series}
        options={options}
      />
    </Spin>
  );
};

export default CompanyGraphCards;
