import kemi_welcome from '@src/assets/img/kemi_welcome.png';
import { Card, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import './InfoCard.scss';

function InfoCard({ stats }) {
  const { user } = useSelector((state) => state.auth);
  function checkValue(val) {
    if (val == null) {
      return 0;
    }
    return val;
  }

  function decideInfoCardSentence(statObj) {
    if (!statObj) {
      return 'Yükleniyor...';
    }
    if (statObj.kkiPendingRequests > 0 || statObj.stockPendingRequests > 0) {
      const text = `Onayınız için bekleyen ${
        statObj.pendingKkiPayment
      } TL tutarında ${checkValue(
        statObj.kkiPendingRequests
      )} Kamu Kurum Ödemesi ve
      ${checkValue(statObj.pendingStockPayment)} TL tutarında ${checkValue(
        statObj.stockPendingRequests
      )} Stok Zararı ödemesi bulunmaktadır `;
      return text;
    } else {
      return `Mevcut bekleyen ödeme bulunmamaktadır.`;
    }
  }

  return (
    <div>
      <Card className="InfoCard">
        <Row justify="space-between">
          <Col span={18}>
            <h2>Kemi Portal'a Hoş Geldiniz, {user?.name}</h2>
            <h3>{decideInfoCardSentence(stats)}</h3>
          </Col>
          <Col span={4} xs={0} sm={4}>
            <img
              className="InfoCard__img"
              src={kemi_welcome}
              alt="kemi_welcome_img"
            />
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default InfoCard;
